<script setup lang="ts">
import type { Game } from "@/types";

defineEmits<{ (event: "toggleFavorite"): void }>();

const props = defineProps<{
	game: Game;
	gameImgHorizontal?: string;
	gameImg?: string;
	gameImgIncrease?: number;
	width?: number;
	height?: number;
	isFavorite?: boolean;
	buttonName?: string;
	defaultBg?: string;
}>();

const widthCss = computed(() => `${props.width}px`);
const alt = computed(() => removeHtmlTags(props.game?.title || ""));
</script>

<template>
	<div class="game-card-horizontal">
		<MGameCard
			:game="game"
			:gameImg="gameImgHorizontal"
			:gameImgIncrease="gameImgIncrease"
			:width="width"
			:height="height"
			:isFavorite="isFavorite"
			:defaultBg="defaultBg"
			@toggle-favorite="$emit('toggleFavorite')"
		>
			<template #top>
				<slot name="top" />
			</template>
		</MGameCard>
		<div class="bottom">
			<NuxtImg class="image-game-mini" width="40" height="45" :src="gameImg" :alt="alt" loading="lazy" format="avif" />
			<AText class="title" :size="14" :modifiers="['ellipsis', 'bold']"><span v-html="game.title" /></AText>
			<AButton variant="primary" @click.prevent>
				<AText :modifiers="['bold', 'uppercase']">{{ buttonName || "Play" }}</AText>
			</AButton>
		</div>
	</div>
</template>

<style scoped lang="scss">
.game-card-horizontal {
	width: v-bind(widthCss);
	.game-card {
		.game-card {
			border-radius: inherit;
		}
		:deep(.image-block) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	.bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 8px;
		padding: 8px;
		.image-game-mini {
			border-radius: 6px;
		}
		.title {
			width: 100%;
		}
		.a-button {
			width: 69px;
			min-width: 69px;
		}
	}
}
</style>
